/**
 * IMPORTS
 */

import { extendTheme } from '@chakra-ui/react';

/**
 * CORE
 */

// @see https://smart-swatch.netlify.app/ (get JS object)
const colors = {
  brand: {
    50: '#e9e4ff',
    100: '#beb2ff',
    200: '#9380ff',
    300: '#674dff',
    400: '#3c1aff',
    500: '#2200e6',
    600: '#1a00b4',
    700: '#110082',
    800: '#070050',
    900: '#030020',
  },
};

const fonts = {
  body: '"Open Sans",Arial,sans-serif',
  heading: 'Oswald,Helvetica,Arial,sans-serif',
};

const Button = {
  baseStyle: {
    borderRadius: 'full',
  },
};

const Heading = {
  baseStyle: {
    color: 'brand.500',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Heading,
  },
});

export default theme;
